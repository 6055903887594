<template>
    <div>
        <form>
            
            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Street Address 1 <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: Apt #3">
                    
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Apt/Suite/Other</label>                
                    <input type="text" class="reg-field" placeholder="eg: J">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>City <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: Doe">
                    
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>State <span class="required-indc">*</span></label>                
                    <select name="" class="reg-field">
                        <option value="">Choose a State</option>
                        <option value="">State </option>
                        <option value="">State </option>
                        <option value="">State </option>
                        <option value="">State </option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4 ">
                    <label>Zip Code <span class="required-indc">*</span></label>                
                    <input type="text" class="reg-field" placeholder="eg: 44600">                    
                </div>                
            </div>

            <div class="form-group">
                <div class="mb-4 form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Continue without Address Validation
                    </label>
                </div>
            </div>

        </form>
        <div class="action-btn split-action mt-5">
            <router-link to="/registration/login-info" class="button-black">Back</router-link>
            <div class="action-next">
                <router-link to="/registration/billing-info" class="button-blue">Next</router-link>
                <router-link to="/registration/billing-info" class="button-green">Save & Continue Late</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BrokerForm'
}
</script>